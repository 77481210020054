/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:18:08
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-09-24 14:37:05
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:18:08
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-12-19 17:48:18
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:18:08
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-12-19 17:43:23
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:18:08
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-12-19 17:41:34
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:18:08
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-12-19 17:40:21
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:18:08
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-12-19 17:38:37
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:18:08
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-12-19 17:37:21
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-24 10:16:41
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-11-10 17:27:28
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signIn',
    name:"signIn",
    component: resolve => require(['../pages/SignIn/signIn'], resolve),
  },   
  {
    path: '/',
    name:"store",
    component: resolve => require(['../pages/HomePage/store'], resolve),
    children:[
      {
        path: '/',
        name:'userData',
        component: resolve => require(['../pages/DataStatistics/userData'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/userData',
        name:'userData',
        component: resolve => require(['../pages/DataStatistics/userData'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      // 销售
      {
        path: '/marketTypeManagement',
        name:'marketTypeManagement',
        component: resolve => require(['../pages/Market/marketTypeManagement'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/marketManagement',
        name:'marketManagement',
        component: resolve => require(['../pages/Market/marketManagement'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/royaltymeSetting',
        name:'royaltymeSetting',
        component: resolve => require(['../pages/Market/royaltymeSetting'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/marketStatistics',
        name:'marketStatistics',
        component: resolve => require(['../pages/Market/marketStatistics'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      // 服务
      {
        path: '/serviceManagement',
        name:'serviceManagement',
        component: resolve => require(['../pages/Service/serviceManagement'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/royaltySetting',
        name:'royaltySetting',
        component: resolve => require(['../pages/Service/royaltySetting'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/serviceStatistics',
        name:'serviceStatistics',
        component: resolve => require(['../pages/Service/serviceStatistics'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/userServiceList',
        name:'userServiceList',
        component: resolve => require(['../pages/Service/userServiceList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/industryClassManagement',
        name:'industryClassManagement',
        component: resolve => require(['../pages/Service/industryClassManagement'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      // 供应商
      {
        path: '/supplierList',
        name:'supplierList',
        component: resolve => require(['../pages/Supplier/supplierList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      // 套餐管理
      {
        path: '/packageManagement',
        name:'packageManagement',
        component: resolve => require(['../pages/Setting/packageManagement'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/userFeebackList',
        name:'userFeebackList',
        component: resolve => require(['../pages/Setting/userFeebackList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/subscribeList',
        name:'subscribeList',
        component: resolve => require(['../pages/Setting/subscribeList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      // 设置文档
      {
        path: '/setupDocument',
        name:'setupDocument',
        component: resolve => require(['../pages/Setting/setupDocument'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/dailyOne',
        name:'dailyOne',
        component: resolve => require(['../pages/Setting/dailyOne'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/shopList',
        name:'shopList',
        component: resolve => require(['../pages/Shop/shopList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/cloudStoreOpenAudit',
        name:'cloudStoreOpenAudit',
        component: resolve => require(['../pages/Setting/cloudStoreOpenAudit'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/cloudStorePaymentApplication',
        name:'cloudStorePaymentApplication',
        component: resolve => require(['../pages/Setting/cloudStorePaymentApplication'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
    ]    
  },
]

const router = new VueRouter({
  routes
})

// // 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/signIn')) {
      window.localStorage.removeItem('token')
      next()
  } else {
      let user = window.localStorage.getItem('token');
      if (!user && to.path !== '/signIn') {
          next({
              path: '/signIn'
          })
      } else {
          next()
      }
  }
});

export default router

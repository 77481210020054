/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:18:08
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-09-24 14:36:52
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-11-07 08:56:59
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-12-19 18:19:49
 */
var storeMenu = [
    {
        icon:"el-icon-s-home",
        index:"DataStatistics",
        sbuMenu:true,
        title:"数据统计",
        type:0,
        subs:[
            {
                icon:"",
                index:"userData",
                sbuMenu:true,
                subs:null,
                title:"用户数据",
                type:0,
            }
        ],
    },{
        icon:"el-icon-s-home",
        index:"Market",
        sbuMenu:true,
        title:"销售",
        type:0,
        subs:[
            {
                icon:"",
                index:"marketTypeManagement",
                sbuMenu:true,
                subs:null,
                title:"销售类型管理",
                type:0,
            },{
                icon:"",
                index:"marketManagement",
                sbuMenu:true,
                subs:null,
                title:"销售管理",
                type:0,
            },{
                icon:"",
                index:"royaltymeSetting",
                sbuMenu:true,
                subs:null,
                title:"绩效金/提成设置",
                type:0,
            },{
                icon:"",
                index:"marketStatistics",
                sbuMenu:true,
                subs:null,
                title:"销售统计",
                type:0,
            }
        ],
    },{
        icon:"el-icon-s-home",
        index:"Service",
        sbuMenu:true,
        title:"服务",
        type:0,
        subs:[
            {
                icon:"",
                index:"serviceManagement",
                sbuMenu:true,
                subs:null,
                title:"客服部管理",
                type:0,
            },{
                icon:"",
                index:"royaltySetting",
                sbuMenu:true,
                subs:null,
                title:"绩效金/提成设置",
                type:0,
            },{
                icon:"",
                index:"serviceStatistics",
                sbuMenu:true,
                subs:null,
                title:"客服统计",
                type:0,
            },{
                icon:"",
                index:"userServiceList",
                sbuMenu:true,
                subs:null,
                title:"用户服务列表",
                type:0,
            },{
                icon:"",
                index:"industryClassManagement",
                sbuMenu:true,
                subs:null,
                title:"行业分类管理",
                type:0,
            },
        ],
    },{
        icon:"el-icon-s-home",
        index:"Supplier",
        sbuMenu:true,
        title:"供应商",
        type:0,
        subs:[
            {
                icon:"",
                index:"supplierList",
                sbuMenu:true,
                subs:null,
                title:"供应商列表",
                type:0,
            }
        ],
    },{
        icon:"el-icon-s-home",
        index:"Shop",
        sbuMenu:true,
        title:"门店管理",
        type:0,
        subs:[
            {
                icon:"",
                index:"shopList",
                sbuMenu:true,
                subs:null,
                title:"门店列表",
                type:0,
            }
        ],
    },{
        icon:"el-icon-s-home",
        index:"Setting",
        sbuMenu:true,
        title:"基本设置",
        type:0,
        subs:[
            {
                icon:"",
                index:"packageManagement",
                sbuMenu:true,
                subs:null,
                title:"套餐管理",
                type:0,
            },{
                icon:"",
                index:"userFeebackList",
                sbuMenu:true,
                subs:null,
                title:"用户反馈",
                type:0,
            },{
                icon:"",
                index:"subscribeList",
                sbuMenu:true,
                subs:null,
                title:"预约演示",
                type:0,
            },{
                icon:"",
                index:"setupDocument",
                sbuMenu:true,
                subs:null,
                title:"文档管理",
                type:0,
            },{
                icon:"",
                index:"dailyOne",
                sbuMenu:true,
                subs:null,
                title:"每日一言",
                type:0,
            },{
                icon:"",
                index:"cloudStoreOpenAudit",
                sbuMenu:true,
                subs:null,
                title:"云店开通审核",
                type:0,
            },{
                icon:"",
                index:"cloudStorePaymentApplication",
                sbuMenu:true,
                subs:null,
                title:"云店支付申请",
                type:0,
            }
        ],
    }
    // ,{
    //     icon:"el-icon-s-home",
    //     index:"Notice",
    //     sbuMenu:true,
    //     title:"公告",
    //     type:0,
    //     // subs:[
    //     //     {
    //     //         icon:"",
    //     //         index:"goodsList",
    //     //         sbuMenu:true,
    //     //         subs:null,
    //     //         title:"供应商列表",
    //     //         type:0,
    //     //     }
    //     // ],
    // },
]

var menuJson = {
    storeMenu
}
export default menuJson